<script setup>
  import { defineComponent, getCurrentInstance, onMounted, ref, reactive, toRefs, watchEffect } from 'vue'


  const props = defineProps({
    address: {
      type: Object
    }
  })
  const data = reactive({
    obj: '2312312',
    ceshi: [1, 2, 3, 4, 5]
  })
</script>

<template>
  <div class="order_user_card">
    <div class="title">{{ $t('Consignee Information') }}</div>
    <div class="rowSS mt15 text-center">
      <div class="rowSC mr20" >
        <div class="lable">{{ $t('Consignee') }}:</div>
        <div>{{ props.address.consignee }}</div>
      </div>
      <div class="rowSC mr20">
        <div class="lable">{{ $t('Address') }}:</div>
        <div class="text_hide3">{{ props.address.address }} {{ props.address.district }} {{ props.address.city }} {{ props.address.province }}</div>
      </div>
      <div class="rowSC mr20" >
        <div class="lable">{{ $t('Phone Number') }}:</div>
        <div>+92 {{ props.address.mobile }}</div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="less">
  .order_user_card {
    padding: 15px;
    .title {
      font-size: 16px;
      font-weight: 600;
    }
    .lable {
      font-size: 14px;
      font-weight: 500;
      margin-right: 5px;
    }
    .text_hide3 {
      max-width: 360px;
    }
  }
</style>
